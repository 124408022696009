.footer-display {
  /* position: absolute; */
  left: 0;
  bottom: 0;
  width: 100%;
  /* height: 90px; */
  /* background-color: red; */
  color: white;
  text-align: center;
  margin-bottom: 1.5em !important;
}
