@media (min-width: 830px) {
  .my-card {
    height: 100%;
    padding: 45px;
    max-width: 600px;
    /*width: 100%;*/
    align-items: center;
    border-radius: 16px;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-bottom: 15px;
    margin-top: 20%;
    margin-right: 10px;
  }

  .mdc-card__media--16-9::before {
    margin-top: 0;
  }

  .my-card__media {
    width: 100%;
    height: max-content;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  img {
    width: 100%;
    height: max-content;
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 500px;
    height: 100%;
    width: 100%;
  }

  .top {
    width: 100%;
  }

  .height-title {
    /*padding-top: 0.2rem;*/
    padding-left: 0.5rem;
    margin-bottom: 20px;
    /* width: 98%;*/
    padding: 13px;
    background-color: #01497a;
    border-radius: 16px;
    font-size: 15px;
    text-align: center;
    color: white;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #blabel {
    margin-top: 2%;
  }

  .sub-group {
    margin-bottom: 10px;
  }

  .mdc-card__primary-action {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 830px) {
  .my-card {
    height: 100%;
    padding: 40px;
    max-width: 600px;
    /*width: 100%;*/
    align-items: center;
    border-radius: 16px;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-bottom: 15px;
    margin-top: 23%;
  }

  .mdc-card__media--16-9::before {
    margin-top: 0;
  }

  .my-card__media {
    width: 100%;
    height: max-content;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  img {
    width: 100%;
    height: max-content;
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 500px;
    height: 100%;
    width: 100%;
  }

  .top {
    width: 100%;
  }

  .height-title {
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    margin-bottom: 20px;
    /*width: 97%;*/
    padding: 13px;
    background-color: #01497a;
    border-radius: 20px;
    font-size: 15px;
    text-align: center;
    color: white;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #blabel {
    margin-top: 2%;
  }

  .sub-group {
    margin-bottom: 10px;
  }

  .mdc-card__primary-action {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    align-items: center;
    text-align: center;
  }
}
