#logoHeader {
  max-width: 130px;
  /* padding-top: 4px; */
}

.header-container {
  height: 90px;
}

/*Large Screen*/
@media (min-width: 720px) {
  .mui-icons {
    transform: scale(1.2);
  }

  .appbar {
    height: 90px;
  }

  .screen {
    background-color: #015893;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-size: 1.7em;*/
    color: Black;
    font-family: "Calibri";
  }

  .card {
    max-width: 650px;
    padding: 13px;
    width: 100%;
    height: auto;
    background-color: white;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 90%;
    height: fit-content;
    margin-bottom: 20px;
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
    color: black;
  }

  .name {
    font-size: 28px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .id {
    margin-top: 5px;
    font-size: 24px;
  }

  .grid-title {
    margin-bottom: 10px;
  }

  .newVisit {
    background-color: transparent;
    border: #c8c8c8 1px solid;
    cursor: pointer;
    width: 50px;
    height: 56px;
    border-radius: 10%;
    margin-left: 5px;
  }

  .newVisit:hover {
    background-color: transparent;
    border: black 1px solid;
    cursor: pointer;
    border-radius: 10%;
  }

  .lastModifiedBy {
    margin-top: 5px;
    font-size: 20px;
    color: rgb(56, 56, 56);
  }

  .grid-page {
    text-align: center;
    align-items: center;
    border-radius: 5px;
    display: grid;
    border-radius: 5px;
    padding-top: 10px;
    font-size: 18px;
  }

  .grid-data {
    /* background-color: #f0f2f4; */
    font-size: 22px;
  }

  .section {
    font-size: 22px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    margin-right: 0px;
  }

  .total-diff {
    width: 80px;
  }

  .grid-row {
    width: 160px;
  }

  .buttonSummary {
    color: black;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .total {
    background-color: rgb(102, 102, 102);
    color: white;
  }

  .input-details {
    background-color: white;
    border: 3px #01497a solid;
    border-radius: 16px;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .input-details-save {
    background-color: white;
    border: 3px #01497a solid;
    border-radius: 16px;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .input-details:hover {
    background-color: #e5e5e5;
  }

  .input-details-save:hover {
    background-color: #e5e5e5;
  }

  .dependency {
    border-radius: 12px;
    padding: 5px;
    color: rgb(230, 230, 230);
  }
  .Severe {
    background: rgb(204, 0, 0);
  }
  .Moderate {
    background: rgb(160, 104, 0);
  }
  .Minimal {
    background: rgb(226, 223, 16);
    color: black;
  }
  .Independent {
    background: green;
  }
  .dependency-row {
    border: none;
    padding-bottom: 0px;
    opacity: 90%;
  }

  .edit-button {
    background-color: white;
    border: 2px grey solid;
    border-radius: 16px;
    padding: 13px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
    font-weight: 600;
    margin-left: 2px;
    cursor: pointer;
  }
}

/*Small Screen*/
@media (max-width: 720px) {
  .mui-icons {
    transform: scale(0.65);
  }

  .appbar {
    height: 90px;
  }

  .screen {
    background-color: #015893;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-size: 1.7em; */
    color: Black;
    font-family: "Calibri";
  }

  .card {
    padding: 13px;
    width: 90%;
    height: auto;
    background-color: white;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 90%;
    height: fit-content;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
  }

  .name {
    font-size: 28px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .id {
    margin-top: 5px;
    font-size: 24px;
  }

  .newVisit {
    background-color: transparent;
    border: #c8c8c8 1px solid;
    cursor: pointer;
    width: 50px;
    height: 56px;
    border-radius: 10%;
    margin-left: 5px;
  }

  .newVisit:hover {
    background-color: transparent;
    border: black 1px solid;
    cursor: pointer;
    border-radius: 10%;
  }

  .lastModifiedBy {
    font-size: 14px;
    color: rgb(56, 56, 56);
  }

  .grid-page {
    text-align: center;
    align-items: center;
    border-radius: 5px;
    display: grid;
    border-radius: 5px;
    padding-top: 10px;
    font-size: 14px;
  }

  .grid-data {
    /* background-color: #f0f2f4; */
    font-size: 18px;
  }

  .section {
    font-size: 18px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .total-diff {
    width: 50px;
  }

  .buttonSummary {
    color: black;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .input-details {
    background-color: white;
    border: 3px #01497a solid;
    border-radius: 16px;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .input-details-save {
    background-color: white;
    border: 3px #01497a solid;
    border-radius: 16px;
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .input-details:hover {
    background-color: #e5e5e5;
  }

  .input-details-sa015893e:focus {
    background-color: #369ce0;
  }

  .dependency {
    border-radius: 10px;
    padding: 5px;
    color: rgb(230, 230, 230);
  }
  .Severe {
    background: rgb(204, 0, 0);
  }
  .Moderate {
    background: rgb(160, 104, 0);
  }
  .Minimal {
    background: rgb(226, 223, 16);
    color: black;
  }
  .Independent {
    background: green;
  }
  .dependency-row {
    border: none;
    padding-bottom: 0px;
    opacity: 95%;
  }

  .search-container {
    padding: 12px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
  }

  .edit-button {
    background-color: white;
    border: 2px grey solid;
    border-radius: 16px;
    padding: 8px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 0px;
    cursor: pointer;
  }
}

.Go-To-Menu {
  width: 70%;
}

.tabs {
  max-width: 650px;
  /* padding: 8px; */
  padding-left: 16px;
  padding-right: 16px;
  width: fit-content;
  height: auto;
  background-color: white;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 90%;
  height: fit-content;
  margin-bottom: 4px;
  margin-top: 4px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  /* font-size: 24px; */
  /* color: black; */
}

.tab {
  font-size: 300px;
}
