/* @media (max-width: 720px) for small screens */
/* @media (min-width: 720px) for larger screens */

/* Physiotherapy css*/

textarea {
  width: 100%;
  display: block;
  outline: none;
}
* {
  margin: 0%;
  font-family: "Calibri";
}

html {
  scroll-behavior: smooth;
}

.alert {
  color: rgb(195, 0, 0);
  margin-top: 1em;
  /* font-size: 1em; */
}

.alert-physio {
  color: rgb(195, 0, 0);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.grip-question2 {
  padding-top: 0.7em;
}

.grip-question3 {
  padding-top: 0.7em;
}

.grip-question-container {
  text-indent: -1em;
  padding-left: 1em;
  word-break: "break-word";
  white-space: "pre-wrap";
}

.timer-buttons-section {
  margin-top: 1.4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.timer-skip-section {
  display: flex;
  justify-content: center;
}

.timer-new {
  display: inline-block;
  background-color: #199ad2;
  font-size: 2.5em;
  color: white;
  font-family: "Fenix";
  width: 8em;
  height: 8em;
  border-radius: 100%;
  text-align: center;
  line-height: 8em;
  align-self: center;
}

.physio-timer-container {
  display: flex;
  flex-direction: column;
  font-family: "Fenix";
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2.5em;
  padding-bottom: 1em;
  background-color: white;
  border-radius: 16px;
  min-width: 70%;
  justify-content: center;
  align-content: center;
}

.physio-summary-container {
  display: flex;
  flex-direction: column;
  font-family: "Fenix";
  padding: 1em;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  min-width: 70%;
}

.physio-table-left-column {
  text-align: left;
}

.physio-table-left-column-indent {
  text-align: left;
  padding-left: 1em;
}

.physio-table-right-column {
  text-align: right;
}

.space-between {
  justify-content: space-between;
}

.fieldset {
  border: transparent;
  padding: 0%;
}

.blue {
  color: #01497a;
}

.TimerDiv {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.indented-radio-buttons {
  margin-left: 1.5em;
}

.next {
  background-color: #01497a;
  margin-top: 0.5em;
  width: 10em;
  height: 3em;
  border-radius: 20px;
  border: 0em;
  text-align: center;
  font-size: 1.5em;
  text-decoration: none;
  color: white;
  margin-bottom: 1em;
  cursor: pointer;
}

/* form input[type="radio"] {
  width: 20px;
  margin-right: 10px;
  float: left;
      clear: none;
      margin: 2px 0 0 2px;
}

form label {
  width: 100px;
  background-color: #e2df3d;
  float: left;
      clear: none;
      display: block;
      padding: 0px 1em 0px 8px;

} */

/* Large screen css */
@media (min-width: 720px) {
  .mui-icons {
    transform: scale(1.3);
  }

  .screen {
    background-color: #e5e5e5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: Black;
    font-family: "Open Sans";
  }

  .next-button {
    background-color: #01497a;
    margin-top: 1em;
    width: 10em;
    height: 3em;
    border-radius: 20px;
    border: 0em;
    text-align: center;
    font-size: 2em;
    text-decoration: none;
    color: white;
    margin-bottom: 1em;
    cursor: pointer;
  }

  /* Use this */
  .main-section {
    background-color: #ffffff;
    border-radius: 35px;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .pageRenderer {
    background-color: #8db0f7;
    /* background-color: #ffffff; */
    /* border-radius: 50px; */
    /* padding-left: 2em; */
    /* padding-right: 2em; */
    /* padding-top: 2em; */
    /* padding-bottom: 2em; */
    min-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  /* 
  .par {
    font-size: 1.5em;
  } */

  .radio {
    height: 1em;
    width: 1em;
    margin-right: 1em;
    margin-bottom: 0.7em;
    margin-top: 0.7em;
  }

  .list-item {
    margin-right: 1em;
    margin-bottom: 0.5em;
    font-size: 1.5em;
  }

  .image-style {
    width: 60%;
  }

  table.style {
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }

  th.style {
    font-size: 1.5em;
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    width: 50%;
    height: 2em;
  }

  td.style {
    font-size: 1em;
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    height: 2em;
  }

  /* .radio-button-label {
    font-size: 1.4em;
  } */

  /* Use this */
  .TimerLayoutBtnStart {
    display: inline-block;
    background-color: #4caf50;
    font-size: 24px;
    font-family: "Fenix";
    width: 15em;
    height: 15em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    align-self: center;
  }

  .TimerLayoutBtnMed {
    padding: 32px 40px;
    display: inline-block;
    background-color: #e2df3d;
    font-size: 24px;
    width: 25em;
    height: 25em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutBtnStop {
    padding: 32px 40px;
    display: inline-block;
    background-color: #c76147;
    font-size: 24px;
    width: 25em;
    height: 25em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutBtnReset {
    padding: 32px 40px;
    display: inline-block;
    font-size: 24px;
    width: 10em;
    height: 3.2em;
    border: 0em;
    border-radius: 20px;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutWords {
    font-size: 2em;
    align-items: center;
  }

  .gripInputs {
    width: 100%;
    color: aliceblue;
  }

  .input-field {
    border: solid 1px lightgrey;
  }

  .physio-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: "Fenix";
    padding: 1em;
    margin-left: 1em;
    margin-right: 1em;
    background-color: white;
    border-radius: 16px;
    justify-content: space-evenly;
  }

  .physio-equipment-container {
    display: flex;
    flex-direction: column;
    font-family: "Fenix";
    padding: 2em;
    background-color: white;
    border-radius: 16px;
    justify-content: center;
    align-content: center;
  }

  .physio-additional-container {
    display: flex;
    font-family: "Fenix";
    padding: 1em;
    background-color: white;
    border-radius: 16px;
    justify-content: center;
    min-width: 70%;
  }

  .question1Form {
    width: 100%;
    padding: 5px;
  }

  .question2Form {
    width: 100%;
    padding: 5px;
  }
}

/* Small screen css */
@media (max-width: 720px) {
  .mui-icons {
    transform: scale(1.3);
  }

  .radio {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .screen {
    background-color: #e5e5e5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: Black;
    font-family: "Open Sans";
  }

  .main-section {
    background-color: #ffffff;
    border-radius: 25px;
    margin-top: 5%;
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: left;
    color: black;
  }

  .pageRenderer {
    /*background-color: #8db0f7;*/
    background-color: #ffffff;
    border-radius: 25px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    min-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }

  .par {
    font-size: 6.75em;
  }

  /* .radio-button-label {
    font-size: 0.75em;
  } */

  .list-item {
    margin-right: 1em;
    margin-bottom: 0.5em;
    font-size: 1em;
  }

  .image-style {
    width: 60%;
  }

  table.style {
    font-size: 1em;
    text-align: center;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
  }

  th.style {
    font-size: 1em;
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    width: 50%;
    height: 2em;
  }

  td.style {
    font-size: 1em;
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    height: 2em;
  }

  .TimerLayoutBtnStart {
    padding: 32px 40px;
    display: inline-block;
    background-color: #4caf50;
    font-size: 24px;
    width: 10em;
    height: 10em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 1em;
    align-self: center;
  }

  .TimerLayoutBtnMed {
    padding: 32px 40px;
    display: inline-block;
    background-color: #e2df3d;
    font-size: 24px;
    width: 10em;
    height: 10em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutBtnStop {
    padding: 32px 40px;
    display: inline-block;
    background-color: #c76147;
    font-size: 24px;
    width: 10em;
    height: 10em;
    border: 0em;
    border-radius: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutBtnReset {
    padding: 32px 40px;
    display: inline-block;
    font-size: 24px;
    width: 10em;
    height: 3.2em;
    border: 0em;
    border-radius: 20px;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    text-decoration: none;
    margin-bottom: 1em;
    cursor: pointer;
    align-self: center;
  }

  .TimerLayoutWords {
    font-size: 2em;
    align-items: center;
  }

  .gripInputs {
    width: 100%;
    color: aliceblue;
  }

  .inputfield {
    border: solid 1px lightgrey;
  }

  .physio-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: "Fenix";
    padding: 1em;
    background-color: white;
    border-radius: 16px;
    justify-content: space-evenly;
  }

  .physio-equipment-container {
    display: flex;
    flex-direction: column;
    font-family: "Fenix";
    padding: 2em;
    background-color: white;
    border-radius: 16px;
    justify-content: center;
    align-content: center;
  }

  .question1Form {
    padding: 5px;
  }

  .question2Form {
    padding: 5px;
  }
}
