@import url("https://fonts.googleapis.com/css2?family=Fenix&display=swap");

.App {
  text-align: center;
}
body {
  background-color: #ebebeb;
  justify-content: center;
  font-size: 1.4em;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.social_work{
	background-color: #7B1FA2!important;
}

.generic{
	background-color: #FDD017!important;
	color:black!important;
}

.physiotherapy{
}

.occupational_therapy{
	background-color: #228c22!important;
}

.nurse{
	background-color: #E75480!important;

}
	