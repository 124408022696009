.my-weight-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    min-height: 150px;
    /*width: 100%;*/
    max-width: 600px;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-top: 20%;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
}