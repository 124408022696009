.row-display {
  display: flex;
  bottom: 0;
  margin-top: 1.5em;
  justify-content: space-between;
  align-items: center;
}

.tableOT {
  padding-right: 2em;
  text-align: center;
}

.tableOTT {
  padding-right: 2em;
  text-align: left;
}

.BIT {
  font-size: 1.5em;
  text-align: center;
}

.BITA {
  font-size: 1.5em;
}

.IADLS {
  font-size: 2em;
  text-align: center;
  /* //color: aqua; */
}

.patient-list-search {
  color: red;
  background-color: aqua;
}

.MuiDivider-root:before,
:after {
  width: 0px;
  display: none;
  background-color: #01497a;
}

.MuiListItemButton-root {
  width: 100%;
  background-color: #01497a;
}

.MuiDivider-wrapper {
  width: 100%;
}

.social-worker-container {
  display: flex;
  font-family: "Fenix";
  padding: 1em;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  min-width: 70%;
}

.summary-container {
  display: flex;
  flex-direction: column;
  font-family: "Fenix";
  padding: 1em;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  width: 70%;
  margin-bottom: 1em;
  display: -webkit-flex!important;
  -webkit-justify-content: center!important;
}
.summary-leftcolumn {
  text-align: left;
  vertical-align: top;
}

.summary-rightcolumn {
  text-align: justify;
  float: right;
  vertical-align: top;
}

.summary-additional-info {
  text-align: justify;
}

.nurse-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Fenix";
  padding: 16px;
  min-width: 50%;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  align-items: baseline;
}

.nurse-activity-box {
  display: flex;
  align-items: center;
}

.gc-container {
  display: flex;
  flex-direction: column;
  padding: 1.6em;
  border: 1em;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  /* //align-items: center; */
  width: 70%;
}

.moca1-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  border-radius: 16px;
  /* justify-content: left; */
  /* align-items: flex-start; */
  width: 70%;
}

.moca-container {
  display: flex;
  flex-direction: column;
  padding: 1.6em;
  border: 1em;
  border-color: #ffffff;
  background-color: white;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.moca-picture-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
}

.moca-confirm-container {
  display: "flex";
  padding: "1em";
  justify-content: "space-evenly";
}

.moca-confirm-title {
  margin-top: "1em";
  margin-right: "1em";
  margin-left: "1em";
  margin-bottom: "0.5em";
}

.moca-summary-container {
  padding: 16px;
  font-size: 20px;
  border: solid 1px;
  border-color: rgb(199, 196, 196);
  border-radius: 16px;
  background-color: white;
  width: 80%;
  border-collapse: separate;
  /* border-spacing: 1em; */
  margin: 10px;
}
.moca-table-left {
  text-align: left;
  padding: 0.5em;
}
.moca-table-left-indent {
  text-align: left;
  vertical-align: top;
  padding-left: 1em;
  padding-bottom: 0.3em;
}

.moca-table-right-indent {
  text-align: justify;
  float: right;
  padding-right: 0.5em;
  padding-bottom: 0.3em;
}

.summary-leftcolumn1 {
  text-align: left;
  vertical-align: top;
}
.summary-rightcolumn1 {
  text-align: justify;
  float: right;
  vertical-align: top;
}

.moca-table-right {
  text-align: right;
  word-wrap: break-word;
  padding: 0.5em;
  /* padding-left: 10px; */
}

.moca-timer-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* font-family: "Fenix"; */
  /* background-color: white; */
  /* border-radius: 16px; */
  /* align-content: center; */
  width: 70%;
  justify-content: center;
}

.moca-timer {
  display: inline-block;
  background-color: #199ad2;
  font-size: 1.3em;
  color: white;
  font-family: "Fenix";
  width: 6.5em;
  height: 6.5em;
  border-radius: 100%;
  text-align: center;
  line-height: 6.5em;
  align-self: center;
}

.moca-timer-buttons-section {
  margin-top: 0.7em;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
}

.patientListGrid{
  display: flex!important;
  flex-direction: column!important;
  display: -webkit-flex!important;
  justify-content: center!important;
  -webkit-justify-content: center!important;
  align-items: center!important;
  -webkit-align-items: center!important;
}

.search-container-ID {
  padding: 16px;
  border: solid 1px;
  border-color: rgb(199, 196, 196);
  border-radius: 15px;
  background-color: white;
  width: 100%;
}

/* Applies when screen width is BIGGER than 720px*/
@media (min-width: 720px) {
  * {
    margin: 0%;
  }

  .id-section {
    margin: 2em 2em;
  }

  .buttons-section {
    width: 80%;
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .next-button {
    background-color: #01497a;
    margin-top: 1em;
    width: 10em;
    height: 2em;
    border-radius: 20px;
    border: 0em;
    text-align: center;
    font-size: 2em;
    text-decoration: none;
    color: white;
    margin-bottom: 1em;
    cursor: pointer;
  }



  .login-container {
    display: flex;
    width: 45%;
    padding: 25px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: white;
    border-style: hidden;
    box-shadow: 1px 2px 9px #000000;
    justify-content: center;
  }

  .case-manager-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 35px;
    background-color: #015893;
    text-align: center;
    justify-content: center;
  }

  .search-container {
    padding: 16px;
    font-family: "Fenix";
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: cyan;
    width: 100%;
  }

  .next-button {
    background-color: #01497a;
    margin-top: 1em;
    width: 10em;
    height: 2em;
    border-radius: 20px;
    border: 0em;
    text-align: center;
    font-size: 2em;
    text-decoration: none;
    color: white;
    margin-bottom: 1em;
    cursor: pointer;
  }

  .screen {
    background-color: #015893;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-size: 1.75em;*/
    color: Black;
    font-family: "Fenix";
    /* padding-top: 20px; */
  }

  .details {
    font-size: 0.85em;
  }

  .textfield {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 5% 25%;
    width: 50%;
    display: flex;
  }

  .subtitle {
    font-size: 1.75em;
  }

  .check {
    height: 1.5em;
    width: 1.5em;
    margin: 1em 1em;
  }

  .search-container-ID tr:nth-child(even) {
    padding: 16px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    width: 40vw;
    background-color: #e6eff5;
  }

  .search-container {
    padding: 16px;
    font-family: "Fenix";
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    max-width: 100%;
    /* background-color: red;//////////////////////////////////////// */
  }

  .search-container-ID tr:hover {
    background-color: #dadcdd;
  }

  .check-label {
    font-size: 1em;
  }

  .pagename {
    font-size: 4em;
    font-family: "Fenix";
    color: white;
  }
}

/* Applies when screen width is SMALLER than 720px*/
@media (max-width: 720px) {
  * {
    margin: 0%;
  }

  .id-section {
    margin: 2em 2em;
  }

  .buttons-section {
    width: 80%;
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .next-button {
    background-color: #01497a;
    margin-top: 1em;
    width: 8em;
    height: 1.75em;
    border-radius: 20px;
    border: 0em;
    text-align: center;
    font-size: 1em;
    text-decoration: none;
    color: white;
    margin-bottom: 1em;
    cursor: pointer;
  }

  .screen {
    background-color: #015893;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-size: 1.75em;*/
    color: Black;
    font-family: "Fenix";
    /* padding-top: 20px; */
  }

  .details {
    font-size: 0.85em;
  }

  .textfield {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 5% 25%;
    width: 50%;
    display: flex;
  }

  .subtitle {
    font-size: 1em;
  }

  .check {
    height: 1em;
    width: 1em;
    margin: 1em 0.7em;
  }

  .search-container {
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    border-style: hidden;
    font-family: "Fenix";
  }

  .login-container {
    display: flex;
    padding: 25px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: white;
    border-style: hidden;
    box-shadow: 1px 2px 9px #000000;
    justify-content: center;
  }

  .check {
    height: 1em;
    width: 1em;
    margin: 1em 0.7em;
  }

  .case-manager-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 35px;
    background-color: #015893;
    text-align: center;
    justify-content: center;
  }

  .check-label {
    font-size: 0.8em;
  }

  .pagename {
    font-size: 100em;
    font-family: "Fenix";
    color: chartreuse;
  }
}
/*
td,
tr {
  background-color: white;
  border-color: white;
  border-style: hidden;
}
*/
.grid-data {
  border-style: none;
  border-color: #e6eff5;
  background-color: white;
}

.MuiList-root {
  width: 80%;
  margin-right: 0%;
  margin-left: 0;
}
.MuiFormControl-root {
  width: 91%;
  align-content: center;
}
h1 {
  color: white;
  font-size: 3em;
  font-family: "Fenix";
  font-weight: normal;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  -webkit-font-smoothing: antialiased;
}

h2 {
  color: white;
  font-size: 2em;
  font-family: "Fenix";
  font-style: normal;
  font-weight: normal;
  text-shadow: 1px 1px 2px grey;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

h3 {
  color: black;
  font-size: 1.2em;
  font-family: "Fenix";
  font-style: normal;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

h4 {
  font-size: 1.1em;
  font-family: "Fenix";
  -webkit-font-smoothing: antialiased;
}

h5 {
  font-size: 1em;
  font-family: "Fenix";
  -webkit-font-smoothing: antialiased;
}
