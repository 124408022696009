.my-question-card {
    min-height: 150px;
    height: auto;
    max-width: 600px;
    width: 100%;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: larger;
    background-color: white;
    margin-bottom: 15px;
}