.grid-data {
  /* background-color: #1f29322a; */
  font-size: 22px;
  /* border-radius: 10px; */
}
.EditButton{
  float: right;
}

@media (min-width: 720px) {
  .searching-container {
    padding: 8px;
    font-size: 20px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: white;
    min-width: 85%;
    border-collapse: separate;
    border-spacing: 1em;
    margin: 10px;
  }
  .Table-Names {
    text-align: left;
    padding-right: 1/2vw;
    max-width: 130px;
  }
  .Table-Content {
    text-align: right;
    word-wrap: break-word;
    padding-left: 10px;
  }
}

@media (min-width: 1920px) {
  .searching-container {
    padding: 16px;
    font-size: 20px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: white;
    min-width: 90%;
    border-collapse: separate;
    border-spacing: 1em;
  }
  .Table-Names {
    text-align: right;
    padding-right: 1/2vw;
    max-width: 70px;
  }
  .Table-Content {
    text-align: center;
    word-wrap: break-word;
    /* width: 5vw; */
    max-width: 100px;
    padding-left: 10px;
    /* padding-right: 4px; */
  }
}

@media (max-width: 720px) {
  .searching-container {
    font-size: 20px;
    border: solid 1px;
    border-color: rgb(199, 196, 196);
    border-radius: 16px;
    background-color: white;
    min-width: 70%;
    border-collapse: separate;
    border-spacing: 1em;
  }
  .Table-Content {
    text-align: left;
    word-wrap: break-word;
    padding-left: 10px;
  }
}
