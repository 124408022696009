/* Not even used */
.search-container {
  padding: 16px;
  font-size: 20px;
  margin: 12px 12px;
  border: solid 1px;
  border-color: rgb(199, 196, 196);
  border-radius: 16px;
  background-color: red;
  width: 80%;
}

/* 
.Table-Title{
    
    
    padding-top: 12px;
    display: block;
} */

.Table-Names {
  text-align: right;
  padding-right: 1/2vw;
}

.Table-Content {
  text-align: center;
  word-wrap: break-word;
  /* width: 5vw; */
  max-width: 120px;
  padding-left: 10px;
  /* padding-right: 4px; */
}

.Table-Title {
  max-width: 650px;

  padding: 13px;
  width: fit-content;
  height: auto;
  background-color: white;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 90%;
  height: fit-content;
  margin-bottom: 20px;
  margin-top: 15px;
  text-align: center;
  font-weight: 600;
  color: black;
  font-size: xx-large;
}

.Submit-Button {
  background-color: #015b98;
  margin-top: 1em;
  width: 10em;
  height: 2em;
  border-radius: 20px;
  border: 0em;
  text-align: center;
  font-size: 1.25em;
  text-decoration: none;
  color: white;
  margin-bottom: 1em;
  cursor: pointer;
}

.FormControlLabel {
  padding-left: 5%;
}
